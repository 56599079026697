import styled from "styled-components";

export const ResultDivider = styled.div`
    width: 100%;
    height: 8px;
    background-color: #f8f8f8;
    margin: 60px 0 0;
`;

export const ResultCountText = styled.div`
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${(props) => props.fontSize};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.66px;
    text-align: left;
    color: #1e1e1e;
`;

export const PrevTextButton = styled.div`
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.48px;
    text-align: right;
    color: #707070;

    position: absolute;
    left: 20px;
`;

export const PrevArrowWrapper = styled.div`
    width: 6px;
    height: 12px;
    margin: 0 11px 0 0;
    object-fit: contain;
`;

//

export const ResultTableCountText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.48px;
    text-align: left;
    color: #1e1e1e;
`;

export const MoreTextButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.42px;
    text-align: right;
    color: #1e1e1e;

    position: absolute;
    right: 20px;
`;

export const MoreArrowWrapper = styled.div`
    width: 4px;
    height: 8px;
    margin: 0 0 0 8px;
    object-fit: contain;
`;

export const NoResultContainer = styled.div`
    width: 335px;
    height: 327px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f8f8f8;
    margin: 40px 20px 0;
`;
