import React from "react";
import ResponsiveMedia from "utils/responsive_media";

import { Container, Box } from "components/style/layoutStyle";
import { MoreArrowWrapper, MoreTextButton, ResultTableCountText } from "./style";
import { AccentText } from "../../../components/style/textStyle";
import Image from "components/image/image";
import { IC_SEARCH_MORE_ARROW } from "../../../constants/media/icon";
import { navigate } from "gatsby-link";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ResultTableTopBar = ({ variety, data, isList }) => {
    const { t, i18n } = useTranslation();

    const { isMobile } = ResponsiveMedia();
    const [title, setTitle] = useState("");

    const moveToMoreList = () => {
        const path = i18n.language === "ko" ? `/support/search/list` : `/${i18n.language}/support/search/list`;

        navigate(path, {
            state: {
                variety: variety.includes("faq") ? "faq" : "notice",
                data: data
            }
        });
    };

    useEffect(() => {
        if (variety.includes("faq")) setTitle("SUPPORT_SEARCH_RESULT_TEXT1");
        if (variety.includes("notice")) setTitle("SUPPORT_SEARCH_RESULT_TEXT2");
    }, [variety]);

    return (
        <Container flexDirection={"column"}>
            <Box padding={isMobile === "true" ? "32px 20px 24px" : "80px 20px 24px"}>
                {data && (
                    <>
                        <ResultTableCountText>
                            {t(title)}&nbsp;<AccentText>{data.length}</AccentText>&nbsp;{t("SUPPORT_COUNT_TEXT")}
                        </ResultTableCountText>
                        {data.length > 5 && !isList && (
                            <MoreTextButton onClick={() => moveToMoreList()}>
                                {t("SUPPORT_BTN_TEXT")}
                                <MoreArrowWrapper>
                                    <Image filename={IC_SEARCH_MORE_ARROW} alt="search more arrow button" />
                                </MoreArrowWrapper>
                            </MoreTextButton>
                        )}
                    </>
                )}
            </Box>
        </Container>
    );
};

export default ResultTableTopBar;
