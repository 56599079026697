import { SupportActions } from "redux/actions";
import { FAQ_CATEGORY } from "constants/data/support";
import { NOTICE_CATEGORY } from "constants/data/support";

const OrganizeSupportData = () => {
    const setFaqData = (faqData, keyword, faqCat, t = () => { }) => {
        if (keyword != null) {
            let filteredFaq = faqData.filter(
                (item) =>
                    (item.node.category !== undefined && item.node.category.toLowerCase().includes(keyword.toLowerCase())) ||
                    (item.node.title !== undefined && item.node.title.toLowerCase().includes(keyword.toLowerCase())) ||
                    (item.node.desc !== undefined && item.node.desc.toLowerCase().includes(keyword.toLowerCase()))
            );

            SupportActions.setFaqData(filteredFaq);
            setFaqCatList(filteredFaq);
        } else {
            const category = t(FAQ_CATEGORY[faqCat]);
            const filteredFaq = faqData.filter((item) => item.node.category !== undefined && item.node.category.includes(category));

            setFaqCatList(faqData);
            SupportActions.setFaqData(faqCat === 0 ? faqData : filteredFaq);
        }
    };

    const setFaqCatList = (data) => {
        const catArray = [
            ["전체", 0],
            ["서비스 이용 관련", 0],
            ["요금제 및 계정", 0],
            ["서명/도장 관련", 0],
            ["법적효력 및 인증", 0],
            ["보안", 0],
            ["기타", 0]
        ];

        const localsCatArray = ["SUPPORT_FAQ_TAB1", "SUPPORT_FAQ_TAB2", "SUPPORT_FAQ_TAB3", "SUPPORT_FAQ_TAB4", "SUPPORT_FAQ_TAB5", "SUPPORT_FAQ_TAB6", "SUPPORT_FAQ_TAB7"];

        for (let i = 0; i < catArray.length; i++) {
            catArray[i][1] = data.filter((item) => item.node.category === catArray[i][0]).length;
            catArray[i][0] = localsCatArray[i];
        }
        catArray[0][1] = data.length;
        SupportActions.setFaqCategoryList(catArray);
    };

    const setNoticeData = (notiData, keyword, notiCat) => {
        if (keyword != null) {
            let filteredNoti = notiData.filter(
                (item) =>
                    (item.node.category !== undefined && item.node.category.toLowerCase().includes(keyword.toLowerCase())) ||
                    (item.node.title !== undefined && item.node.title.toLowerCase().includes(keyword.toLowerCase())) ||
                    (item.node.desc !== undefined && item.node.desc.toLowerCase().includes(keyword.toLowerCase()))
            );

            SupportActions.setNoticeData(filteredNoti);
        } else {
            let filteredNoti = notiData.filter((item) => item.node.category !== undefined && item.node.category.includes(NOTICE_CATEGORY[notiCat]));
            SupportActions.setNoticeData(notiCat === 0 ? notiData : filteredNoti);
        }
    };

    return {
        setFaqData,
        setNoticeData
    };
};

export default OrganizeSupportData;
