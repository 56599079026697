import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ResponsiveMedia from "utils/responsive_media";

import { Container, Box, Wrapper } from "components/style/layoutStyle";
import { NoticeTableBox, NoticeTableRow, NoticeTableCell, NoticeDivision, Content } from "./style";
import { HighlightText } from "components/highlightText";
import { navigate } from "gatsby-link";
import Pagination from "components/navigation/pagination";
import ConvertDate from "../../../utils/convert_date";
import { useTranslation } from "react-i18next";

const NoticeTable = ({ notiData, category, keyword, searchResult }) => {
    const { i18n } = useTranslation();

    const { isMobile } = ResponsiveMedia();
    const [page, setPage] = useState(1);

    const moveToNoticeDetail = (id) => {
        const path = i18n.language === "ko" ? `/support/notice/detail/${id}` : `/${i18n.language}/support/notice/detail/${id}`;

        navigate(path);
    };

    const handleChangePage = (event) => {
        setPage(event);
    };

    useEffect(() => {
        setPage(1);
    }, [category]);

    function Row(props) {
        const { row } = props;

        return (
            <NoticeTableRow mobile={isMobile}>
                <NoticeTableCell division order={isMobile === "true" ? "0" : "0"}>
                    <NoticeDivision mobile={isMobile}>
                        <Content>
                            <HighlightText text={row.category} keyword={keyword} />
                        </Content>
                    </NoticeDivision>
                </NoticeTableCell>
                <NoticeTableCell date order={isMobile === "true" ? "1" : "2"}>
                    {ConvertDate(row.date)}
                </NoticeTableCell>
                <NoticeTableCell text order={isMobile === "true" ? "2" : "1"} style={{ cursor: "pointer" }} onClick={() => moveToNoticeDetail(row.id)}>
                    <Content>
                        <HighlightText text={row.title} keyword={keyword} />
                    </Content>
                </NoticeTableCell>
            </NoticeTableRow>
        );
    }

    return (
        <Container>
            <Box margin={isMobile === "true" ? "0" : "0 20px 0"} flexDirection={"column"}>
                <NoticeTableBox>
                    {isMobile || searchResult ? (
                        ""
                    ) : (
                        <Box>
                            <NoticeTableRow head>
                                <NoticeTableCell division>구분</NoticeTableCell>
                                <NoticeTableCell text>제목</NoticeTableCell>
                                <NoticeTableCell date>날짜</NoticeTableCell>
                            </NoticeTableRow>
                        </Box>
                    )}
                    {notiData &&
                        notiData.slice((page - 1) * 10, searchResult ? page * 5 : page * 10).map((row, index) => {
                            return <Row key={index} row={row.node} />;
                        })}
                </NoticeTableBox>
                {notiData && !searchResult && (
                    <Wrapper margin={"68px 0 120px"}>
                        <Pagination data={notiData} onClickEvent={handleChangePage} />
                    </Wrapper>
                )}
            </Box>
        </Container>
    );
};

export default connect(
    (state) => ({
        keyword: state.support.search_keyword,
        notiData: state.support.notice_data
    }),
    null
)(NoticeTable);
