import React from "react";
import { connect } from "react-redux";
import ResponsiveMedia from "utils/responsive_media";
import { Container, Box } from "components/style/layoutStyle";
import { PrevArrowWrapper, PrevTextButton, ResultCountText } from "./style";
import { AccentText } from "../../../components/style/textStyle";
import Image from "components/image/image";
import { IC_SEARCH_PREV_ARROW } from "constants/media/icon";
import { navigate } from "gatsby-link";
import { SUPPORT_TAB } from "constants/data/support";
import { useTranslation } from "react-i18next";

const ResultCount = ({ actTab, faqData, notiData, keyword, isList }) => {
    const { t, i18n } = useTranslation();

    const { isMobile } = ResponsiveMedia();

    const moveToPrev = () => {
        const path = i18n.language === "ko" ? SUPPORT_TAB.items[actTab].path : `/${i18n.language}${SUPPORT_TAB.items[actTab].path}`;

        navigate(isList ? -1 : path);
    };

    return (
        <Container minHeight={"32px"}>
            <Box justifycontent={"center"}>
                {!isMobile && (
                    <PrevTextButton onClick={() => moveToPrev()}>
                        <PrevArrowWrapper>
                            <Image filename={IC_SEARCH_PREV_ARROW} alt="prev arrow button" />
                        </PrevArrowWrapper>
                        {t(isList ? "SUPPORT_BACK_BTN_TEXT2" : "SUPPORT_BACK_BTN_TEXT")}
                    </PrevTextButton>
                )}
                {faqData && notiData && !isList && (
                    <ResultCountText fontSize={isMobile === "true" ? "18px" : "22px"}>
                        <AccentText>"{keyword}"</AccentText>
                        &nbsp;{t("SUPPORT_SEARCH_TEXT", { count: i18n.language === "ko" ? faqData.length + notiData.length : faqData.length })}&nbsp;
                        {i18n.language === "ko" && (
                            <>
                                <AccentText>{faqData.length + notiData.length}</AccentText>&nbsp;{t("SUPPORT_COUNT_TEXT")}
                            </>
                        )}
                    </ResultCountText>
                )}
            </Box>
        </Container>
    );
};

export default connect(
    (state) => ({
        actTab: state.support.support_tab
    }),
    null
)(ResultCount);
