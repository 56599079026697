import React, { useEffect } from "react";
import { connect } from "react-redux";
import { navigate } from "gatsby-link";
import ResponsiveMedia from "utils/responsive_media";

import FaqTable from "../table/faqTable";
import NoticeTable from "../table/noticeTable";
import OrganizeSupportData from "../organizeSupportData";

import { Container, Box } from "components/style/layoutStyle";
import { NoResultContainer, ResultDivider } from "./style";
import ResultCount from "./resultCount";
import ResultTableTopBar from "./resultTableTopBar";
import { TextNormal } from "../../../components/style/textStyle";
import { SquareButton } from "components/button";
import { SUPPORT_TAB } from "constants/data/support";
import { useTranslation } from "react-i18next";

const SearchResult = ({ actTab, notice, faq, notiData, faqData, keyword }) => {
    const { i18n } = useTranslation();

    const { isMobile } = ResponsiveMedia();
    const { setNoticeData, setFaqData } = OrganizeSupportData();

    useEffect(() => {
        setNoticeData(notice, keyword, 0);
        setFaqData(faq, keyword, 0);
    }, [keyword]);

    const moveToPrev = () => {
        const path = i18n.language === "ko" ? SUPPORT_TAB.items[actTab].path : `/${i18n.language}${SUPPORT_TAB.items[actTab].path}`;

        navigate(path);
    };

    return (
        <Container margin={isMobile === "true" ? "0 0 100px" : "0 0 140px"}>
            <Box flexDirection={"column"}>
                <ResultCount notiData={notiData} faqData={faqData} keyword={keyword} />
                {faqData && faqData.length > 0 && (
                    <>
                        {isMobile && <ResultDivider />}
                        <ResultTableTopBar data={faqData} variety={"faq"} />
                        <FaqTable searchResult />
                    </>
                )}
                {notiData && notiData.length > 0 && i18n.language === "ko" && (
                    <>
                        {isMobile && <ResultDivider />}
                        <ResultTableTopBar data={notiData} variety={"notice"} />
                        <NoticeTable searchResult />
                    </>
                )}
                {faqData && notiData && faqData.length === 0 && notiData.length === 0 && isMobile && (
                    <NoResultContainer>
                        <TextNormal margin={"0 0 29px"} type={"18"}>
                            검색 결과가 없습니다.
                        </TextNormal>
                        <SquareButton width={"90px"} height={"40px"} fontFamily={"NotoSansKR-Normal"} fontSize={"16px"} text={"돌아가기"} onClickEvent={() => moveToPrev()} />
                    </NoResultContainer>
                )}
            </Box>
        </Container>
    );
};

export default connect(
    (state) => ({
        keyword: state.support.search_keyword,
        faqData: state.support.faq_data,
        notiData: state.support.notice_data,
        actTab: state.support.support_tab
    }),
    null
)(SearchResult);
